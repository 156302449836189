<script lang="ts">
import { useBaseMixin } from '@/composables/useBaseMixin'
import AppSettingsIcon from '@/components/icons/AppSettingsIcon.vue'
import SensorIcon from '@/assets/icons/sensor.svg?component'
import { version } from '@/version.js'
import { OnClickOutside } from '@vueuse/components'


export default {
  name: 'HeaderUserMenu',
  components: {
    AppSettingsIcon,
    SensorIcon,
    OnClickOutside
  },
  setup() {
    return {
      ...useBaseMixin()
    }
  },
  data() {
    return {
      toggleSubMenu: false
    }
  },
  computed: {
    version: () => version
  },
  methods: {
    onClose: function (): void {
      this.toggleSubMenu = false
    }
  }
}
</script>

<template>
  <div :class="$style.userNavContainer" v-on:keyup.esc="onClose">
    <button
      :class="$style.userMenuToggle"
      ref="trigger"
      data-testid="mainNavigation__menuToggleBtn"
      @click="toggleSubMenu = !toggleSubMenu"
    >
      <AppSettingsIcon :class="$style.userIcon" :superAdmin="isSuperAdmin" alt="" />
    </button>
    <OnClickOutside @trigger="onClose">
    <div
      v-show="toggleSubMenu"
      :class="$style.userMenu"
    >
      <h5>{{ currentLoggedInUser.username }}</h5>
      <nav :class="$style.userNavigation">
        <ul>
          <li>
            <router-link
              :to="{ name: 'Management' }"
              @click="onClose"
              data-testid="mainNavigation_managementBtn"
            >
              <SensorIcon :class="$style.menuIcon" alt="" />
              Management
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Profile' }"
              @click="onClose"
              data-testid="mainNavigation__userprofileBtn"
            >
              <img
                src="/assets/icons/user.svg"
                alt=""
                :class="$style.menuIcon"
                evrac-wl="icon-primary-color-filter"
              />
              Profile
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Settings' }"
              @click="onClose"
              data-testid="mainNavigation__usersettingsBtn"
            >
              <img
                src="/assets/icons/settingsNew.svg"
                alt=""
                :class="$style.menuIcon"
                evrac-wl="icon-primary-color-filter"
              />
              Settings
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Security' }"
              @click="onClose"
              data-testid="mainNavigation__securityBtn"
            >
              <img
                src="/assets/icons/security.svg"
                alt=""
                :class="$style.menuIcon"
                evrac-wl="icon-primary-color-filter"
              />
              Security
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Support' }"
              @click="onClose"
              data-testid="mainNavigation__supportBtn"
            >
              <img
                src="/assets/icons/envelope.svg"
                alt=""
                :class="$style.menuIcon"
                evrac-wl="icon-primary-color-filter"
              />
              Support
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Logout' }"
              @click="onClose"
              data-testid="mainNavigation__logoutBtn"
            >
              <img
                src="/assets/icons/logout.svg"
                alt=""
                :class="$style.menuIcon"
                evrac-wl="icon-primary-color-filter"
              />
              Logout
            </router-link>
          </li>
        </ul>
      </nav>
      <h5 v-if="isSuperAdmin" :class="$style.dashboardVersion">
        <span>Evercloud Version</span> {{ version }}
      </h5>
    </div>
    </OnClickOutside>
  </div>
</template>

<style module>
.userNavContainer {
  display: flex;
  align-items: center;
  position: relative;
}
.userMenuToggle {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 48px;
  flex: 1 auto;
}
.userMenuToggle:focus {
  outline: 1px dashed rgba(255, 255, 255, 0.3);
}
.userMenuToggle:hover {
  cursor: pointer;
}
.userIcon {
  width: 30px;
  height: 30px;
}
.userMenu {
  background: white;
  position: absolute;
  top: 68px;
  right: 0;
  width: 230px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.userMenu nav {
  padding: 0;
}
.userMenu h5 {
  font-size: 1.125em;
  font-weight: 400;
  color: #212529;
  padding: 15px 10px;
  background: #e5e5e5;
  margin-bottom: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.userMenu ul {
  list-style: none;
  padding: 0px 5px;
  margin-bottom: 0;
}
.userMenu li {
  padding: 5px 0;
}
.userMenu a,
.userMenu button {
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  color: #212529;
  padding: 3% 6%;
  display: grid;
  align-items: center;
  grid-template-columns: 1.5rem 1fr;
  grid-gap: 1rem;
  justify-content: center;
}
.userMenu button:hover {
  cursor: pointer;
}
.userMenu .menuIcon {
  color: #03033d;
  fill: currentColor;
  width: 1.25rem;
  height: 1.25rem;
}
.userMenu a:focus {
  outline: 1px dashed rgba(0, 0, 0, 0.25);
}
.userMenu a:hover,
.userMenu button:hover {
  background: #f2f2f2;
}

.userMenu .dashboardVersion {
  font-size: 0.8rem;
  overflow-wrap: break-word;
}

.userMenu .dashboardVersion span {
  display: block;
  text-align: center;
  margin-bottom: 0.5rem;
}
</style>
