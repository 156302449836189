import {
  Notification as ApiNotification,
  SteamTrapNotificationResolution as ApiSteamTrapNotificationResolution,
  UserNotificationAction as ApiUserNotificationAction,
  NotificationCountSummary
} from '@/client/api'
import { CustomerMinimal } from './customer'
import { Sensor } from './sensors'

/**
 * Interface used to perform any partial notification update. The only property required
 * is the id of the notification being updated.
 */
export interface NotificationUpdate {
  id: string
  customer?: CustomerMinimal | null
  customerId?: string | null
  entityId?: string | null
  entityTypeId?: string | null
  eventId?: string | null
  eventTimestamp?: number | null
  eventType?: string | null
  eventTypeId?: string | null
  facility?: string | null
  location?: string | null
  notificationType?: string | null
  resolutionTimestamp?: number | null
  resolutionUserId?: string | null
  sensor?: Sensor | null
  sensorId?: string | null
  serviceTag?: string | null
  steamTrapResolveDetail?: SteamTrapNotificationResolution | null
  userActions?: UserNotificationAction[] | null
}

export interface NotificationOption {
  label: string
  type?: string
  description?: string
  entries?: Array<NotificationOption>
}

export interface ISteamTrapNotificationResolution extends ApiSteamTrapNotificationResolution {}

export class SteamTrapNotificationResolution implements ISteamTrapNotificationResolution {
  public eventConfirmed: boolean | null = null
  public inspected = false
  public inspectionTimestamp?: number | null = null
  public notes?: string | null = null
  public replaced = false
  public replacementTimestamp?: number | null = null

  constructor(data?: Partial<SteamTrapNotificationResolution>) {
    if (data) {
      const cleanData = JSON.parse(JSON.stringify(data))
      Object.assign(this, cleanData)
    }
  }
}

export interface IUserNotificationAction extends ApiUserNotificationAction {}

export class UserNotificationAction implements IUserNotificationAction {
  public static readonly TYPE = {
    snoozed: 1,
    resolved: 2
  }

  public id = ''
  public notificationId = ''
  public userId = ''
  public actionTypeId = 0
  public expiresTimestamp?: number | null = null
  public createdAt: number

  constructor(data?: Partial<UserNotificationAction>) {
    if (data) {
      const cleanData = JSON.parse(JSON.stringify(data))
      Object.assign(this, cleanData)
    }
  }
}

export class NotificationCountsSummary implements NotificationCountSummary {
  public totalCount = 0
  public activeCount = 0
  public resolvedCount = 0
  public snoozedCount = 0

  constructor(data?: Partial<NotificationCountsSummary>) {
    if (data) {
      const cleanData = JSON.parse(JSON.stringify(data))
      Object.assign(this, cleanData)
    }
  }
}

export interface INotification extends ApiNotification {}

export class Notification implements INotification {
  get isResolvable() {
    return !this.resolutionTimestamp
  }

  get notificationTypeDescription() {
    if (this.notificationType === 'INTERMITTENT_BLOWTHROUGH') {
      return `Intermittent Blowthrough ${this.eventDetail ? '&ndash;' : ''} ${
        this.eventDetail || ''
      }`
    }
    return Notification.types[this.notificationType]
  }

  public static readonly STATUS = {
    active: 0,
    snoozed: 1,
    resolved: 2
  }

  public static categories = {
    BLOWTHROUGH: 'Blowthrough',
    INTERMITTENT_BLOWTHROUGH: 'Intermittent Blowthrough',
    // STEAM_ON: 'Steam On',
    // STEAM_OFF: 'Steam Off',
    FAILED_COLD: 'Unexpected Cold',
    EVERSENSOR_STATUS: 'Eversensor Status',
    LEAKING_BY: 'Leaking By'
  }

  public static types = {
    FAILED_COLD: 'Unexpected Cold',
    BLOWTHROUGH: 'Blowthrough',
    INTERMITTENT_BLOWTHROUGH: 'Intermittent Blowthrough',
    LEAKING_BY: 'Leaking By',
    // STEAM_ON: 'Steam On',
    // STEAM_OFF: 'Steam Off',
    SERVICE_NEEDED: 'Eversensor Status – Service Needed',
    OFFLINE: 'Eversensor Status – Offline',
    SLEEP: 'Eversensor Status – Sleep'
  }

  public id = ''
  public customer?: CustomerMinimal | null = null
  public customerId = ''
  public entityId = ''
  public entityTypeId = ''
  public eventId = ''
  public eventTimestamp = 0
  public eventType?: ApiNotification.eventType | null = null
  public eventTypeId = ''
  public eventDetail = ''
  public facility?: string | null = null
  public location?: string | null = null
  public notificationType?: ApiNotification.notificationType | null = null
  public resolutionTimestamp?: number | null = null
  public resolutionUserId?: string | null = null
  public sensor?: Sensor | null = null
  public sensorId?: string | null = null
  public serviceTag?: string | null = null
  public steamTrapResolveDetail?: SteamTrapNotificationResolution | null = null
  public userActions?: UserNotificationAction[] | null = null
  public notes?: string | null = null;

  public constructor(data?: Partial<INotification>) {
    if (data) {
      const cleanData = JSON.parse(JSON.stringify(data))
      Object.assign(this, cleanData)

      if (cleanData.sensor) {
        this.sensor = new Sensor(cleanData.sensor)
      }

      if (cleanData.customer) {
        this.customer = new CustomerMinimal(cleanData.customer)
      }

      if (cleanData.steamTrapResolveDetail) {
        this.steamTrapResolveDetail = new SteamTrapNotificationResolution(
          cleanData.steamTrapResolveDetail
        )
      }

      if (cleanData.userActions) {
        this.userActions = cleanData.userActions.map((action) => new UserNotificationAction(action))
      }
    }
  }

  public getNotificationStatus(): number {
    if (this.resolutionTimestamp) {
      return Notification.STATUS.resolved
    }
    return Notification.STATUS.active
  }
}
