<script lang="ts">
export default {
    name: "DeprecationNotice"
};
</script>
<template>
    <section :class="$style.deprecationNotice">
        <h1 :class="$style.logoHeader">
            <span class="hidden">Evercloud by Everactive</span>
            <img :class="$style.logo" src="/assets/logos/logo-inverted.svg" alt="Everactive Logo" />
        </h1>
        <h2>Browser Not Supported</h2>
        <p>
            Evercloud no longer supports Internet Explorer. We recommend installing
            one of these web browsers:
        </p>
        <ul>
            <li>
                <a href="https://www.google.com/chrome/" target="_blank" title="download chrome browser">Google Chrome</a>
            </li>

            <li>
                <a href="https://www.mozilla.org/en-US/firefox/" target="_blank" title="download firefox browser">Mozilla
                    Firefox</a>
            </li>

            <li>
                <a href="https://www.microsoft.com/en-us/edge" target="_blank"
                    title="download microsoft edge browser">Microsoft Edge</a>
            </li>
        </ul>

        <h3>Contact Everactive Support Team</h3>
        <p>
            If you need any assistance downloading or using a supported browser,
            please email <span>success@everactive.com</span>
        </p>
    </section>
</template>
  
<style module>
.logoHeader {
    margin: 0 0 20px;
    line-height: 1;
}

.logo {
    max-width: 10rem;
}

.deprecationNotice {
    color: white;
}

.deprecationNotice h2 {
    font-family: "Gilroy Bold", sans-serif;
}

.deprecationNotice h3 {
    font-family: "Gilroy", sans-serif;
    margin-top: 4rem;
}

.deprecationNotice a,
.deprecationNotice span {
    color: white;
    text-decoration: underline;
}

.deprecationNotice p {
    margin: 0.5rem 0;
}

.deprecationNotice a:hover {
    color: white;
}
</style>
  